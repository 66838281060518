import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
// import PageParticles from "../components/PageParticles"
export default ({ data }: any) => {
  return (
    <>
      <Layout pageData={data.wpOffer} />
      {/* <PageParticles /> */}
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String) {
    wpOffer(id: { eq: $id }) {
      id
      title
      content
      status
      language {
        code
        locale
      }
      translations {
        language {
          code
          homeUrl
          id
          locale
          name
          slug
        }
        link
      }
      seo {
        metaRobotsNofollow
        metaKeywords
        metaDesc
        schema {
          raw
        }
        opengraphModifiedTime
        opengraphType
        opengraphSiteName
        opengraphTitle
        opengraphPublisher
        opengraphImage {
          width
          height
          guid
          mimeType
        }
        title
        opengraphUrl
      }
      blocks {
        attributesJSON
        name
        innerBlocks {
          attributesJSON
        }
      }
    }
  }
`
